@import '../sass/_vars';
.area-details {
  display: flex;
  flex-flow: column wrap;
  color: $main;
  font-weight: bold;
  label {
    display: inline-block;
    margin-right: 5px; } }
.area-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  &__title {
    font-size: 22px; }
  &__type {
    display: block;
    font-weight: normal;
    font-size: 16px; }
  &__icon {
    display: block;
    width: 60px;
    height: 60px; } }
.circle-icon {
  background: url("../images/detail_icons/circle_details.png") center center no-repeat; }

.rect-icon {
  background: url("../images/detail_icons/rect_details.png") center center no-repeat; }

.poly-icon {
  background: url("../images/detail_icons/poly_details.png") center center no-repeat; }

.input__text {
  font-weight: normal;
  border-bottom:  2px solid $main;
  text-align: center;
  color: $main; }
.text-align-left {
  text-align: left; }
.input-control {
  display: block;
  margin: 5px 0; }

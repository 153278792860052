.svg-canvas {
  position: absolute;
  left: 0;
  top: 0;
  margin: 10px;
  z-index: 3;
  &.cursor-move-tool {
    cursor: url(../images/cursors/cursor-move-tool.png), pointer; }
  &.cursor-circle-tool {
    cursor: url(../images/cursors/cursor-circle-tool.png), pointer; }
  &.cursor-rect-tool {
    cursor: url(../images/cursors/cursor-rect-tool.png), pointer; }
  &.cursor-poly-tool {
    cursor: url(../images/cursors/cursor-poly-tool.png), pointer; } }


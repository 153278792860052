@font-face {
  font-family: 'fontello';  
  src: url('../../fonts/fontello.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.icon.pointer{
  cursor: pointer;
}
.icon.disabled{
  cursor: not-allowed;
}
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never; 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-settings:before { content: '\2699'; } /* '⚙' */
.icon-cancel:before { content: '\2715'; } /* '✕' */
.icon-down-open:before { content: '\e75c'; } /* '' */
.icon-up-open:before { content: '\e75f'; } /* '' */
.icon-download:before { content: '\f02e'; } /* '' */
.icon-delete:before { content: '\f083'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-toggle-off:before { content: '\f204'; } /* '' */
.icon-toggle-on:before { content: '\f205'; } /* '' */
@import '../sass/_vars';

.imgmapper__details {
  display: flex;
  flex-direction: row;
  width: 100%;
  display: flex;
  padding: 20px;
  margin-right: -15px;
  align-items: top;
  justify-content: center;
  box-shadow: 0 -4px 4px rgba(0,0,0, 0.2);
  background-color: $soft-gray;
  .column__wrap {
    border: 1px solid $dark-gray;
    background-color: $details-bg;
    border-radius: 13px;
    flex-grow: 1;
    overflow-x: hidden;
    margin-left: 15px; }

  .column__title {
    background: $main;
    color: $accent;
    font-weight: bold;
    padding: 5px 10px; }

  .column__content {
    margin: 10px 15px 10px 15px; } }

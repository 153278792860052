@import '../sass/_vars';

.tools {
  margin-top: 40px; }

.tools-items {
  display: flex;
  flex-direction: column; }

.toolbar {
  width: 90px;
  min-height: 450px;
  text-align: center;
  background-color: $main; }

.app__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-top: 10px; } }

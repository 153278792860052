@import  '/sass/_vars';
@import  '/sass/reset.scss';
/*fonts*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
/*common*/

body {
  font-family: Montserrat, sans-serif;
  font-size: 16px; }
input {
  border: none; }




.imgmapper {
  width: 100%;
  heigth: 100%; }

.wopkplace__work-img {
  width: 100%; }



.cursor_circle {
  cursor: url('images/cursors/cursor-circle-tool.png'), pointer; }

.cursor_rect {
  cursor: url('images/cursors/cursor-rect-tool.png'), pointer; }

.cursor_poly {
  cursor: url('images/cursors/cursor-poly-tool.png'), pointer; }

.cursor_move {
  cursor: url('images/cursors/cursor-move-tool.png'), pointer; }




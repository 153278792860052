@import '../sass/_vars';
.work-img__header {
  width: 100%;
  height: 70px;
  display: flex;
  padding: 0 45px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 4px rgba(0,0,0, 0.2);
  background-color: $soft-gray;
  .map-name {
    font-size: 25px;
    font-weight: 700;
    color: $main; } }
.code-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 5px; }
.text-wrap {
  flex-shrink: 0;
  text-align: left;
  width: 100%;
  margin: 0 0 auto 0;
  .btn-wrap {
    width: 100%;
    text-align: center; } }
